import React from "react";
import Layout from "web-common/src/components/Layout";
import Helmet from "react-helmet";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import Container from "react-bootstrap/Container";
import { emptyLocalizedContext } from "web-common/src/services/LocalizedContextService";

const FootballXBoots = () => {
  const { siteUrl } = useSiteMetadata();
  const pageTitle = "Football X Boots";

  return (
    <Layout pageName={pageTitle} pageType={"promotion"} localizedContext={emptyLocalizedContext}>
      <Helmet>
        <link rel="canonical" href={`${siteUrl}/football-x-boots/`} />
        <title>{pageTitle}</title>
        <script type="text/javascript" src="https://s.wayin.com/ui/ngx.embed.min.js"></script>
      </Helmet>
      <div className="page_football-x-boots">
        <Container fluid data-cy="football-x-boots">
          <h1 className="sr-only sr-only-focusable">Football X Boots</h1>
          <iframe
            src="https://xd.wayin.com/display/container/dc/284d313d-9a3f-40af-be71-2057f8b5a929"
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="1289"
            id="ngxFrame284d313d-9a3f-40af-be71-2057f8b5a929"
            title="Sure football competition"
            allowTransparency={true}
          ></iframe>
        </Container>
      </div>
    </Layout>
  );
};

export default FootballXBoots;
